





















































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ASSESSMENT_ERROR,
  TENANT_INFO,
  TENANT_INFO_DETAILS,
  TENANT_INFO_LOADING
} from "@/store/modules/candidates/constants";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import {
  GET_ASSESSMENT_PDF,
  GET_ASSESSMENT_SUMMARY,
  GET_ROLE_BY_NAME
} from "@/store/modules/common/constants";
import { AssessmentSummary } from "@/interfaces/assessments";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import {
  clean_string,
  download_file,
  generate_random_key
} from "@/utils/global";
import IndividualSummary from "@/components/assessments/IndividualSummary.vue";
import IndividualTalents from "@/components/assessments/IndividualTalentsGrowth.vue";
import { get_random_color } from "@/utils/colors";
import AssessmentError from "@/components/assessments/AssessmentError.vue";
import ArcheTypesKeys = AssessmentSummary.ArcheTypesKeys;
import Archetype = AssessmentSummary.Archetype;
import { AssessmentErrorTypes } from "@/store/modules/candidates/interfaces";
import ContextualArchetype from "@/components/assessments/ContextualArchetype.vue";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "AssessmentReport",
  components: {
    ContextualArchetype,
    AssessmentError,
    IndividualTalents,
    IndividualSummary,
    GoBackHeader
  },
  beforeRouteEnter(to, from, next) {
    const param_id = to.params.assessment_id;
    if (!param_id) next(false);
    else next();
  },
  beforeRouteUpdate(to, from, next) {
    const param_id = to.params.assessment_id;
    if (!param_id) next(false);
    else next();
  },
  data() {
    return {
      data_loading: true, // loading for fetching assessment summary from DB
      pdf_loading: false, // loading for fetching assessment pdf file from DB
      assessment_summary: null as AssessmentSummary.Root | null,
      individualist_summary: [] as string[],
      individualist_talents: [] as string[],
      individualist_growth: [] as string[]
    };
  },
  computed: {
    AssessmentErrorTypes() {
      return AssessmentErrorTypes;
    },
    AssessmentSummary() {
      return AssessmentSummary;
    },
    ...mapGetters("candidate", {
      tenant_info: TENANT_INFO,
      tenant_info_details: TENANT_INFO_DETAILS
    }),
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME
    }),
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  watch: {
    async tenant_info_details(n) {
      if (n) {
        await this.init();
      }
    }
  },
  async mounted() {
    await this.init();
  },
  methods: {
    generate_random_key,
    ...mapActions("common", {
      fetch_assessment_summary: GET_ASSESSMENT_SUMMARY,
      fetch_assessment_pdf: GET_ASSESSMENT_PDF
    }),
    ...mapMutations("candidate", {
      set_assessment_error: ASSESSMENT_ERROR,
      set_assessment_loading: TENANT_INFO_LOADING
    }),
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION,
      root_error: ROOT_ERROR
    }),
    async init() {
      this.data_loading = true;
      const recruiter_role = this.get_role_by_name("recruiter");
      const recruiter_admin_role = this.get_role_by_name("recruiter admin");
      if (
        this.get_user_details.role_id === recruiter_role.id ||
        this.get_user_details.role_id === recruiter_admin_role.id
      ) {
        const summary = await this.fetch_assessment_summary(
          this.$route.params.assessment_id
        );
        if (summary) {
          summary.assessment.scores.dimensions.map(
            (val: AssessmentSummary.Dimension) =>
              (val.color = get_random_color())
          );
          this.assessment_summary = summary.assessment;
          this.data_loading = false;
          this.set_assessment_loading(false);
        } else this.set_assessment_error(true);
      } else await this.init_candidate_process();

      this.process_individualist_summary();
      this.data_loading = false;
    },
    async init_candidate_process() {
      if (this.tenant_info.loading) return;
      this.data_loading = true;
      // Check if assessment summary exists
      if (
        this.tenant_info.details &&
        this.tenant_info.details.assessment_summary
      ) {
        this.tenant_info.details.assessment_summary.assessment.scores.dimensions.map(
          (val: AssessmentSummary.Dimension) => (val.color = get_random_color())
        );
        this.assessment_summary =
          this.tenant_info.details.assessment_summary.assessment;
      }
      // If assessment summary not exists => fetch assessment summary
      else if (this.tenant_info.details) {
        const summary = await this.fetch_assessment_summary(
          this.tenant_info.details.id
        );
        summary.assessment.scores.dimensions.map(
          (val: AssessmentSummary.Dimension) => (val.color = get_random_color())
        );
        this.assessment_summary = summary.assessment;
      } else {
        this.set_assessment_error(true);
      }
      this.data_loading = false;
      this.set_assessment_loading(false);
    },
    get_all_arche_types(key: ArcheTypesKeys) {
      if (!this.assessment_summary) return null;
      const arche_types = this.assessment_summary.scores.allArchetypes;
      if (!arche_types) return null;
      const found = arche_types.find(
        (val: Archetype) => clean_string(val.key) === clean_string(key)
      );
      if (!found) return null;
      return found;
    },
    process_individualist_summary() {
      const data = this.get_all_arche_types(ArcheTypesKeys.individualist);
      if (data) {
        this.individualist_summary = data.statements.portrait;
        this.individualist_talents = data.statements.talents;
        this.individualist_growth = data.statements.growthOpportunities;
      }
    },
    async get_assessment_report() {
      this.pdf_loading = true;
      const recruiter_role = this.get_role_by_name("recruiter");
      const recruiter_admin_role = this.get_role_by_name("recruiter admin");
      let assessment_id: number | null = null;
      if (
        this.get_user_details.role_id === recruiter_role.id ||
        this.get_user_details.role_id === recruiter_admin_role.id
      ) {
        assessment_id = parseInt(this.$route.params.assessment_id);
      } else assessment_id = this.tenant_info.details.id;
      if (!assessment_id) {
        this.root_error(this.$t("errors.assessment-report-failed"));
        this.pdf_loading = false;
        return;
      }
      const url = await this.fetch_assessment_pdf(assessment_id);
      if (!url) this.root_error(this.$t("errors.assessment-report-failed"));
      else if (url.includes("http") || url.includes("https"))
        await download_file(url);
      else this.root_notification(this.$t("success-messages.pdf-creation"));
      this.pdf_loading = false;
    }
  }
});
